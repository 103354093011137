const CompanyModule = {
    state: {
        companies: [],                // Speichert Unternehmensdatens�tze
        loggedInCompanyDetails: null, // Speichert Details eines einzelnen Unternehmens
        counter: 0,                   // Gesamtanzahl der Unternehmen
        searchResultsCounter: 0,      // Gesamtanzahl der Suchergebnisse
        searchAttempt: 0,             // Anzahl der Suchversuche
        isSearching: false            // Flag, um anzuzeigen, ob eine Suche im Gange ist
    },

    mutations: {
        setItems(state, items) {
            state.companies = items.companyDtosList || [];

            if (state.isSearching) {
                state.searchResultsCounter = items.count;
                state.searchAttempt++;
            }
        },

        setCounter(state, item) {
            state.counter = item;  // Setzt die Gesamtanzahl der Unternehmen
        },

        resetSearch(state) {
            state.searchResultsCounter = 0;  // Setzt den Z�hler der Suchergebnisse zur�ck
            state.searchAttempt = 0;  // Setzt die Anzahl der Suchversuche zur�ck
            state.isSearching = false;  // Setzt das Such-Flag zur�ck
        },

        isSearching(state, item) {
            state.isSearching = item;  // Setzt das Flag f�r die laufende Suche
        },

        setLoggedInCompany(state, payload) {
            state.loggedInCompanyDetails = payload;  // Setzt die Details eines einzelnen Unternehmens
        }
    },

    getters: {
        companies: (state) => {
            return state.companies;  // Gibt die Liste der Unternehmen zur�ck
        },

        counter(state) {
            return state.counter;  // Gibt die Gesamtanzahl der Unternehmen zur�ck
        },

        searchResultsCounter(state) {
            return state.searchResultsCounter;  // Gibt die Anzahl der Suchergebnisse zur�ck
        },

        getLoggedInCompany(state) {
            return state.loggedInCompanyDetails;  // Gibt die Details eines einzelnen Unternehmens zur�ck
        },

        isCompanyLoggedIn(state) {
            return !!state.loggedInCompanyDetails;  // Gibt einen booleschen Wert zur�ck, der anzeigt, ob ein Unternehmen eingeloggt ist
        }
    },

    actions: {
        setItems({ commit }, items) {
            commit("setItems", items);  // Commit, um die Liste der Unternehmen zu setzen
        },

        setCounter({ commit }, item) {
            commit("setCounter", item);  // Commit, um die Unternehmensanzahl zu setzen
        },

        resetSearch({ commit }) {
            commit("resetSearch");  // Commit, um den suchbezogenen Zustand zur�ckzusetzen
        },

        isSearching({ commit }, item) {
            commit("isSearching", item);  // Commit, um das Flag f�r die laufende Suche zu setzen
        },

        setLoggedInCompany({ commit }, data) {
            commit('setLoggedInCompany', data);  // Commit, um die Details eines einzelnen Unternehmens zu setzen
        }
    }
};

export default CompanyModule;