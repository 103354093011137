import { createStore } from 'vuex'
import language from './language.module'
import publicService from './publicService.module'
import cluster from './cluster.module'
import publicservicesToClusters from './mappingPublicserviceToCluster.module'
import tag from './tag.module'
import userFeedback from './userFeedback.module'
import version from './version.module'
import information from './information.module'
import principal from './principal.module'
import keycloak from './keycloak.module'
import plz from './plz.module'
import company from './company.module'
import DataExchangeModule from './dataExchange.module'

/**
 * Auto load store-modules
 */
const context = require.context('./', true, /\.module\.js$/i);
let storeModules = {
    language,
    publicService,
    cluster,
    publicservicesToClusters,
    tag,
    userFeedback,
    version,
    information,
    principal,
    keycloak,
    plz,
    DataExchangeModule,
    company
}
/*
 * from: ./store-modules/language.module.js
* 
* Name of the file automatically becomes namespace of module, 
* in this case "language"
*/
context.keys().forEach(file => {
    let name = file.replace(/\.module\.js$/i, "").replace('./', "");
    storeModules[name] = context(file).default || context(file)
    storeModules[name].namespaced = true
});

const store = createStore({
    modules: storeModules
});

export default store;




